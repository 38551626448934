import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FixedGroupHeaderService } from './fixed-group-header.service';
let uniqueId = 0;
@Directive({
  selector: '[appFixedGroupHeader]',
  exportAs: 'fixedGroupHeader',
})
export class FixedGroupHeaderDirective implements OnDestroy, OnInit, AfterViewInit {
  private id: string = `appFixedGroupHeader-${uniqueId++}`;
  private _destroy = new Subject<void>();
  private _data = new BehaviorSubject<any>(null);
  readonly data$ = this._data.asObservable().pipe(distinctUntilChanged());
  get data() {
    return this._data.getValue();
  }
  @Input('fixedGroupHeaderData')
  set data(val: any) {
    this._data.next(val);
  }

  @Input('fixedGroupHeaderContainer')
  parent?: ElementRef<HTMLElement>;
  constructor(private el: ElementRef<HTMLElement>, private _serv: FixedGroupHeaderService) {}
  isActive() {
    const rect = this.el.nativeElement.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (this.parent?.nativeElement.clientHeight || window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= this.parent?.nativeElement.clientHeight
    );
  }
  ngOnInit() {
    this._serv.setSection(this.id, this.isActive());
    this._serv.setSectionData(this.id, this.data);
    this.data$.pipe(takeUntil(this._destroy), distinctUntilChanged(), debounceTime(100)).subscribe((x) => {
      this._serv.setSectionData(this.id, x), this._serv.setSection(this.id, this.isActive());
    });
  }
  ngAfterViewInit() {
    fromEvent(this.parent?.nativeElement ? this.parent.nativeElement : window.document, 'scroll')
      .pipe(takeUntil(this._destroy), debounceTime(100))
      .subscribe(() => this._serv.setSection(this.id, this.isActive()));
  }
  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
}
